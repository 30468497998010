import './PuxGallery.scss'

import FsLightbox from 'fslightbox-react'
import React, { FunctionComponent, useState } from 'react'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'

import PuxCarousel from '../PuxCarousel/PuxCarousel'
import PuxGalleryItem, { PuxGalleryItemType } from './PuxGalleryItem'

export interface PuxGalleryType {
  contentItemId?: string
  displayText: string
  galleryList: {
    contentItems: PuxGalleryItemType[]
  }
  galleryItemsToLightbox: boolean
}

const rootClass = `gallery`
const CAROUSEL_MIN_WIDTH = 600
const CAROUSEL_MIN_ITEMS = 6

const PuxGallery: FunctionComponent<PuxGalleryType> = (props) => {
  const { width } = useWindowDimensions()
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  })
  const lightboxHandler = (id: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: id,
    })
  }
  const slidesPaths: string[] = []
  const slides = props.galleryList?.contentItems?.map((item, idx) => {
    slidesPaths.push(item.galleryItemImage.resizePaths[0])

    return (
      <PuxGalleryItem
        key={idx}
        id={idx}
        galleryItemImage={item.galleryItemImage}
        galleryLightbox={props.galleryItemsToLightbox}
        galleryLightboxHandler={lightboxHandler}
        alt={item.alt}
      />
    )
  })

  const useCarousel: boolean =
    slides?.length >= CAROUSEL_MIN_ITEMS && width >= CAROUSEL_MIN_WIDTH

  return (
    <div className={rootClass}>
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
      <div className={`${rootClass}-items`}>
        {useCarousel ? (
          <PuxCarousel
            slidesPerView={6}
            externalNavigation={true}
            contentItemId={props.contentItemId}
            slides={slides}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 40,
              },
            }}
          />
        ) : (
          <div className={`${rootClass}-static`}>{slides}</div>
        )}
        {props.galleryItemsToLightbox ? (
          <FsLightbox
            sources={slidesPaths}
            type='image'
            toggler={lightboxController.toggler}
            sourceIndex={lightboxController.slide}
          />
        ) : null}
      </div>
    </div>
  )
}

export default PuxGallery

import React, { FunctionComponent } from 'react'

import PuxPicture, { PuxPictureType } from '../content/mediaImage/PuxPicture'

export interface PuxGalleryItemType {
  contentItemId?: string
  id: number
  alt: string
  galleryItemImage: PuxPictureType
  galleryLightbox: boolean
  galleryLightboxHandler: (id: number) => void
}

const rootClass = `gallery-item`

const PuxGalleryItem: FunctionComponent<PuxGalleryItemType> = (props) => (
  <div
    onClick={() => props.galleryLightboxHandler(props.id)}
    className={props.galleryLightbox ? rootClass + ` .has-lightbox` : rootClass}
  >
    <PuxPicture
      {...props.galleryItemImage}
      lightbox={false}
      width={150}
      height={50}
    />
  </div>
)

export default PuxGalleryItem
